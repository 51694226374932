import { api_base_url } from '../../../../../global_data';
import axios from 'axios';
import { navigate } from 'gatsby';

const createdAndAddItem = async ({
  state,
  userGlobalState,
  deckId,
  setFeedbackState
}) => {
  try {
    setFeedbackState({
      isActive: true,
      isLoading: true
    });

    const { imgUrl, fileUrl } = state;

    if (!state.title) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'The title is empty'
      });
    } else if (!state.description) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'The description is empty'
      });
    } else if (!state.imgUrl) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'No cover image uploaded'
      });
    } else if (!state.fileUrl) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'No deck item file uploaded'
      });
    } else {
      const formData = new FormData();

      formData.append('imgUrl', imgUrl);

      formData.append('fileUrl', fileUrl);

      formData.append(
        'data',
        JSON.stringify({
          deckItem: {
            ...state,
            // overwrite
            artist: userGlobalState.artist._id
          },
          deckId: deckId,
          userId: userGlobalState.user._id
        })
      );

      await axios({
        url: `${api_base_url}/deckItems/createAndAdd`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });

      navigate('/deck?_id=' + deckId);

      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: false,
        message: 'Item Uploaded'
      });
    }
  } catch (err) {
    console.log('err', err);
    setFeedbackState({
      isActive: true,
      isError: true,
      isLoading: false,
      message: err.response.data || 'Error'
    });
  }
};

export default createdAndAddItem;
