import React from 'react';
import PageHeader from '../../lib/components/PageHeader/PageHeader';
import MyItemForm from '../components/MyItemForm';
import Breadcrumbs from '../../lib/components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';

const CreateItem = ({
  onSubmit,
  onChangeField,
  userGlobalState,
  data,
  deckId,
  deckName
}) => {
  return (
    <>
      <Helmet>
        <title>Create Deck Item</title>
      </Helmet>
      <PageHeader>
        <Breadcrumbs
          crumbsArr={[
            {
              title: 'My Decks',
              to: `/dashboard/myDecks?artistId=${userGlobalState.artist._id}`
            },
            { title: deckName, to: `/deck?_id=${deckId}` },
            { title: 'Upload Deck Item' }
          ]}
        />
      </PageHeader>
      <MyItemForm
        value={data}
        onSubmit={onSubmit}
        onChangeField={onChangeField}
        cta="Create"
      />
    </>
  );
};

export default CreateItem;
