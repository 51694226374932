
import React from "react";
import CreateItemContainer from '../../../Dashboard/myItems/containers/CreateItemContainer'


const CreateItemPage = ({ location }) => {

  return (
    <CreateItemContainer location={location}/>
  )
}

export default CreateItemPage;
