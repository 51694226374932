
import React, { useContext } from "react";
import useSetState from '../../../utils/useSetState';
import CreateItem from '../CreateItem';
import createdAndAddItem from './utils/createdAndAddItem';
import { UserContext } from '../../../Context/UserContext/UserContext';
import queryString from 'query-string';
import Navbar from "../../../lib/components/Navbar/Navbar";
import CenterContainer from "../../../lib/components/CenterContainer/CenterContainer";
import DashboardWrapper from "../../DashboardWrapper";
import { FeedbackContext } from '../../../Context/FeedbackContext/FeedbackContext';
import { cloneDeep } from 'lodash';


const CreateItemContainer = ({ location }) => {

    const [state, setState] = useSetState({});

    const { userGlobalState } = useContext(UserContext);
    const { setFeedbackState } = useContext(FeedbackContext);

    const deckId = queryString.parse(location.search).deckId;
    const deckName = queryString.parse(location.search).deck;

    const onSubmit = (e) => {
        e.preventDefault();
        createdAndAddItem({ state, userGlobalState, deckId, setFeedbackState });
    } 

    const onChangeField = ({ fieldName }, e) => {
        const stateClone = cloneDeep(state);
        stateClone[fieldName] = e.target.value;
        setState(stateClone);
    }

    return (
        <>
            <Navbar/>
                <CenterContainer>
                    <DashboardWrapper>
                        <CreateItem 
                            onSubmit={onSubmit} 
                            onChangeField={onChangeField} 
                            userGlobalState={userGlobalState}
                            data={state}
                            deckId={deckId}
                            deckName={deckName}
                        />
                    </DashboardWrapper>
                </CenterContainer>
            
        </>
    );
};

export default CreateItemContainer;
